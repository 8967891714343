'use client';

import React from 'react';
import Image from 'next/image';
import logo from '@/public/staticfiles/logo/logo_white.svg?url';
import type { LogoPropTypes } from '../lib/types';
import * as SC from './styled';

function Index({
  href = '/',
  ...props
}: Omit<React.ComponentProps<typeof SC.Wrapper>, 'href' | 'children'> & {
  href?: Parameters<typeof SC.Wrapper>[0]['href'];
} & LogoPropTypes) {
  return (
    <SC.Wrapper href={href} {...props}>
      <Image height={24} src={logo} alt="nicegram logo" />
    </SC.Wrapper>
  );
}

export default Index;
