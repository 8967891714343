import * as settingsApi from "./settings.crud";
import type * as SettingsTypes from "./types";

const settingsService = {
  settings: settingsApi,
};

export default settingsService;

export type { SettingsTypes };
