'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useDeviceInfoContext } from '@/processes/device-info';
import Logo from '@/entities/logo';
import { Container } from '@/shared/ui';
import { formatTelegramLink } from '@/shared/lib/helpers';
import { GridColumnHeader, GeneralSectionWrapper, Copyright, GridWrapper, StyledUL, Wrapper } from './styled';

export const navLinks = [
  { labelKey: 'download', href: 'https://nicegram.app/download' },
  { labelKey: 'home', href: '/' },
  { labelKey: 'earn', href: 'https://nicegram.app/earn' },
  { labelKey: 'features', href: 'https://nicegram.app/features' },
  { labelKey: 'community', href: 'https://nicegram.app/community' },
  { labelKey: 'faq', href: 'https://nicegram.app/faq' },
  { labelKey: 'learn_more', href: 'https://nicegram.app/articles' },
  { labelKey: 'ambassadors', href: 'https://nicegram.app/ambassadors' },
];

function Footer() {
  const t = useTranslations('footer');
  const { type: deviceType } = useDeviceInfoContext();

  const contactsLinks = React.useMemo(
    () => [
      {
        href: 'https://nicegram.app/privacy-policy ',
        title: t('privacy_policy'),
        rel: 'noreferrer',
      },
      {
        href: 'https://appvillis.com/eula.html',
        title: t('terms_of_use'),
        rel: 'noreferrer nofollow',
      },
      {
        href: 'https://chatbot.nicegram.app/',
        title: t('lily_ai'),
        rel: 'noreferrer',
      },

      {
        href: 'https://wiki.nicegram.app/',
        title: t('wiki'),
        rel: 'noreferrer',
      },
    ],
    [t],
  );

  const chatsLinks = React.useMemo(
    () => [
      { href: 'mailto:nicegram@appvillis.com', title: t('support'), rel: 'noreferrer nofollow' },
      {
        href: 'mailto:investor.relations@appvillis.com',
        title: t('investor_relations'),
        rel: 'noreferrer nofollow',
      },
      {
        href: 'mailto:press@appvillis.com',
        title: t('press'),
        rel: 'noreferrer nofollow',
      },
    ],
    [t],
  );

  return (
    <Wrapper>
      <Container>
        <GridWrapper>
          <Logo />
          <div>
            <GridColumnHeader>{t('general')}</GridColumnHeader>
            <GeneralSectionWrapper>
              <StyledUL>
                {navLinks.map((link) => (
                  <li key={link.labelKey}>
                    <Link target="_blank" rel="noreferrer" href={`${link.href}`}>
                      {t(`${link.labelKey}` as keyof IntlMessages['footer'])}
                    </Link>
                  </li>
                ))}
              </StyledUL>
            </GeneralSectionWrapper>
          </div>
          <div>
            <GridColumnHeader>{t('about')}</GridColumnHeader>
            <GeneralSectionWrapper>
              <StyledUL>
                {contactsLinks.map((link) => (
                  <li key={link.title}>
                    <Link rel={link.rel} target="_blank" href={link.href}>
                      {link.title}
                    </Link>
                  </li>
                ))}
              </StyledUL>
            </GeneralSectionWrapper>
          </div>
          <div>
            <GridColumnHeader>{t('contacts')}</GridColumnHeader>
            <StyledUL>
              {chatsLinks.map((link) => (
                <li key={link.title}>
                  <a rel={link.rel} target="_blank" href={link.href}>
                    {link.title}
                  </a>
                </li>
              ))}
            </StyledUL>
          </div>
        </GridWrapper>
        <Copyright>
          <div>© {new Date().getFullYear()} Nicegram</div>
          <div>
            <a
              aria-label="tg"
              target="_blank"
              rel="noreferrer nofollow"
              href={formatTelegramLink('https://t.me/nicegramapp', deviceType)}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM9.79999 17.5007C10.0918 17.5007 10.2252 17.3709 10.3864 17.2139L10.4 17.2007L11.8419 15.7986L14.84 18.0136C15.3918 18.3181 15.7901 18.1605 15.9276 17.5013L17.8961 8.225C18.0976 7.41698 17.5881 7.0505 17.0602 7.29018L5.50116 11.7473C4.71215 12.0637 4.71675 12.5039 5.35734 12.7001L8.32364 13.6259L15.1909 9.29341C15.5151 9.09682 15.8127 9.20251 15.5685 9.41925L10.004 14.4407L10.008 14.4437L10.0042 14.4414L9.79999 17.5007Z"
                  fill="currentColor"
                />
              </svg>
            </a>
          </div>
        </Copyright>
      </Container>
    </Wrapper>
  );
}

export { Footer };
