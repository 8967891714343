"use client";

import React from "react";
import { useLocale } from "next-intl";
import Tippy from "@tippyjs/react";
import {
  usePathname,
  useRouter,
  useScrollLock,
  useMediaQuery,
} from "@/shared/lib/hooks";
import { getDirValueByLocale } from "@/shared/lib/helpers";
import { options } from "../model";
import type { LanguageOption } from "../model/types";
import { LanguageOptionsList } from "./LanguageOptionsList";
import * as SC from "./styled";

function LanguageSwitcher() {
  const [, startTransition] = React.useTransition();
  const locale = useLocale();
  const router = useRouter();
  const pathname = usePathname();
  const [visible, setVisible] = React.useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");

  useScrollLock(visible);

  const activeOption = React.useMemo(
    () => options.find(({ iso }) => iso === locale),
    [locale],
  );

  function handleLanguageOptionSelect(option: LanguageOption) {
    startTransition(() => {
      document.documentElement.lang = option.iso;
      document.documentElement.dir = getDirValueByLocale(option.iso);

      router.replace(pathname, { locale: option.iso });
      onClose();
    });
  }

  const onOpen = React.useCallback(() => {
    setVisible(true);
  }, []);

  const onClose = React.useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <SC.Wrapper>
      <Tippy
        visible={visible}
        onClickOutside={onClose}
        maxWidth="none"
        zIndex={10}
        placement={isMobile ? "bottom-end" : "bottom-start"}
        offset={isMobile ? [0, -26] : [-10, -26]}
        interactive
        animation={false}
        content={
          <LanguageOptionsList
            onSelect={handleLanguageOptionSelect}
            options={options}
            onClose={onClose}
          />
        }
      >
        <SC.ActiveLanguageOption $hide={visible} onClick={onOpen}>
          <SC.ActiveLanguageOptionFlag $src={activeOption?.icon} />
          <SC.ActiveLanguageOptionLabel>
            {activeOption?.label}
          </SC.ActiveLanguageOptionLabel>
        </SC.ActiveLanguageOption>
      </Tippy>
    </SC.Wrapper>
  );
}

export default LanguageSwitcher;
