import styled from "styled-components";
import { Wrapper as Container } from "@/shared/ui/Container/styled";

export const StyledUL = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 15px;

  li:hover {
    opacity: 1;
  }

  li {
    a {
      color: var(--primary-text);
      text-decoration: none;
      font-weight: 400;
      font-size: 16px;
    }
  }

  @media (max-width: 768px) {
    overflow: hidden;
    visibility: visible;
    opacity: 1;
    max-height: 450px;

    > li:first-child {
      margin: 0 auto;
    }
  }
`;

export const GeneralSectionWrapper = styled.div`
  display: flex;
  gap: 15px;

  ${StyledUL} {
    flex-grow: 1;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Copyright = styled.div`
  padding: 22px 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: capitalize;
  color: rgba(255, 255, 255, 0.4);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;

  > div:last-child {
    display: flex;
    gap: 15px;
  }

  a {
    color: var(--primary-text);
    text-decoration: none;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    grid-gap: 20px;
    justify-content: center;

    * {
      justify-content: center;
    }
  }
`;

export const GridColumnHeader = styled.div`
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 30px;
  }
`;

export const GridWrapper = styled.div`
  margin-bottom: 40px;
  display: grid;
  align-items: baseline;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    opacity: 0.6;
  }

  @media (max-width: 768px) {
    > a {
      justify-content: center;
    }

    text-align: center;
    grid-template-columns: 1fr;
    gap: 50px;
    > div {
      justify-content: center;
    }
  }
`;

export const Wrapper = styled.footer`
  position: relative;
  z-index: 2;
  padding-top: 50px;
  border-top: 1px solid var(--border-color);

  @media (max-width: 768px) {
    padding-top: 50px;

    ${Container} {
      padding: 0;
    }
  }
`;
