export const API_AUTH_ENDPOINTS = {
  getMe: () => '/api/getMe',
  login: () => '/api/login',
  loginWithMiniApp: () => '/api/login-mini-app',
  logout: () => '/api/logout',
};

export const API_AUTH_ENDPOINTS_V2 = {
  getMe: () => 'api/v7/unblock-feature/me',
  login: () => 'api/v7/unblock-feature/login',
  loginWithMiniApp: () => 'api/v7/unblock-feature/login-mini-app',
  logout: () => 'api/v7/unblock-feature/logout',
};
