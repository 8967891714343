import ky from 'ky';
import config from '../constants/config';

export const createKyInstance = (service: 'nicegram') => {
  const instance = ky.create({
    prefixUrl: config[service].baseURL,
    headers: {
      'content-type': 'application/json; charset=utf-8',
    },
    timeout: false,
  });

  return instance;
};
