'use client';

import React from 'react';
import api from '@/shared/api';
import { UserInfoType } from '../lib/types';
import { usePathname, useRouter } from '@/shared/lib/hooks';
import { UserSettings } from '@/shared/api/settingsService/types';
import { common } from '@/shared/lib/constants';

type UserInfoContextProps = UserInfoType;

const UserInfoContext = React.createContext<UserInfoContextProps>({
  user: null,
  isFetching: true,
  fetchUser: () => {},
  updateSettings: () => {},
});

function UserInfoContextProvider({ isTelegramBot = false, children }: UserInfoContextProviderProps) {
  const pathname = usePathname();
  const router = useRouter();
  const [user, setUser] = React.useState<UserInfoType['user']>(null);
  const [isFetching, setIsFetching] = React.useState(true);

  const fetchUser = React.useCallback(async () => {
    try {
      setIsFetching(true);
      const { data: user } = await api.authService.auth.fetchGetMe();

      if (!user) {
        throw new Error('Unauthorized');
      }

      const { data: settings } = await api.settingsService.settings.fetchGetUserSettings(user.user_id);

      if (!settings) {
        throw new Error('Unauthorized');
      }

      setUser({ ...user, settings });
      if (pathname === '/') {
        router.push('/settings');
      }
    } catch (e: unknown) {
      console.log(e);
      setUser(null);
      throw e;
    } finally {
      setIsFetching(false);
    }
  }, [pathname]);

  const updateSettings = React.useCallback((settings: UserSettings) => {
    setUser((prev) =>
      prev
        ? {
            ...prev,
            settings: {
              ...prev?.settings,
              ...settings,
            },
          }
        : prev,
    );
  }, []);

  // TODO refactor ot for new auth flow
  React.useEffect(() => {
    async function checkAuth() {
      if (isTelegramBot && !document.getElementById('script-init-telegram-mini-app')) {
        const newScript = document.createElement('script');
        newScript.src = 'https://telegram.org/js/telegram-web-app.js';
        newScript.setAttribute('id', 'script-init-telegram-mini-app');

        document.body.append(newScript);

        const loadListener = async () => {
          try {
            const tgInitData = window.Telegram?.WebApp?.initData || '';

            if (!user && tgInitData) {
              await api.authService.auth.fetchPostLoginWithMiniApp(tgInitData);
            }

            await fetchUser();
          } catch (e) {
            window.open(common.BASE_URL, '_blank');
          } finally {
            newScript.removeEventListener('load', loadListener);
          }
        };

        newScript.addEventListener('load', loadListener);
      } else {
        await fetchUser();
      }
    }

    checkAuth();
  }, [fetchUser, isTelegramBot]);

  return (
    <UserInfoContext.Provider value={{ user, isFetching, fetchUser, updateSettings }}>
      {children}
    </UserInfoContext.Provider>
  );
}

function useUserInfoContext() {
  return React.useContext<UserInfoContextProps>(UserInfoContext);
}

interface UserInfoContextProviderProps extends React.PropsWithChildren {
  isTelegramBot?: boolean;
}

export { UserInfoContextProvider, useUserInfoContext };
