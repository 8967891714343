"use client";

import React, { PropsWithChildren } from "react";
import Footer from "@/features/footer";
import Navbar from "@/widgets/navbar";
import { Cloud } from "@/shared/ui";

function NavbarFooterLayout({ children }: PropsWithChildren) {
  return (
    <>
      <Navbar />
      <Cloud />
      <main>{children}</main>
      <Footer />
    </>
  );
}

export default NavbarFooterLayout;
