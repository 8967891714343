'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import { useUserInfoContext } from '@/processes/auth';
import { useDeviceInfoContext } from '@/processes/device-info';
import LanguageSwitcher from '@/features/language-switcher';
import Logo from '@/entities/logo';
import api from '@/shared/api';
import { useScroll } from '../lib';
import { LogoutIcon } from '@/shared/ui/Icon/svg';
import * as SC from './styled';

function Navbar() {
  const t = useTranslations();
  const { user, fetchUser } = useUserInfoContext();
  const { isTelegramBot } = useDeviceInfoContext();
  const [isNavbarPinned, setIsNavbarPinned] = React.useState(false);
  useScroll({
    thresholdExceedCallback: () => setIsNavbarPinned(true),
    thresholdReturnCallback: () => setIsNavbarPinned(false),
  });

  return (
    <>
      <div style={{ marginBottom: isNavbarPinned ? 70 : 0 }} />
      <SC.Wrapper $pinned={isNavbarPinned}>
        <nav>
          <Logo />
          <div>
            <LanguageSwitcher />
            {user && !isTelegramBot && (
              <SC.LogoutButton
                onClick={() =>
                  api.authService.auth
                    .fetchPostLogout()
                    .then(fetchUser)
                    .catch(() => {})
                }
              >
                <LogoutIcon />
                {t('login.logout')}
              </SC.LogoutButton>
            )}
          </div>
        </nav>
      </SC.Wrapper>
    </>
  );
}

export default Navbar;
