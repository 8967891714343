import styled from "styled-components";
import { Link } from "@/shared/ui";

export const Wrapper = styled(Link)`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  text-decoration: none;
  color: white;
`;
