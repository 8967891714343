import React from "react";
import { useLocale } from "next-intl";
import type { LanguageOption } from "../model/types";
import * as SC from "./styled";

type LanguageOptionsListProps = {
  options: LanguageOption[];
  onSelect: (option: LanguageOption) => void;
  onClose: () => void;
};

function LanguageOptionsList({
  options,
  onSelect = () => {},
  onClose,
}: LanguageOptionsListProps) {
  const locale = useLocale();

  const activeOption = options.find((option) => option.iso === locale);

  return (
    <SC.Dropdown>
      {activeOption && (
        <SC.DropdownHeader>
          <SC.LanguageOption onClick={onClose}>
            <SC.LanguageOptionFlag $src={activeOption.icon} />
            <SC.LanguageOptionLabel>
              {activeOption.label}
            </SC.LanguageOptionLabel>
          </SC.LanguageOption>
        </SC.DropdownHeader>
      )}

      <SC.LanguageOptionsList>
        {options
          .filter((option) => option.iso !== locale)
          .map((option) => {
            const { label, icon, iso } = option;

            return (
              <SC.LanguageOption key={iso} onClick={() => onSelect(option)}>
                <SC.LanguageOptionFlag $src={icon} />
                <SC.LanguageOptionLabel>{label}</SC.LanguageOptionLabel>
              </SC.LanguageOption>
            );
          })}
      </SC.LanguageOptionsList>
    </SC.Dropdown>
  );
}

export { LanguageOptionsList };
export type { LanguageOptionsListProps };
