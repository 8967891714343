"use client";

import React from "react";

function useScroll({
  thresholdOffset = 20,
  thresholdExceedCallback = () => {},
  thresholdReturnCallback = () => {},
}: {
  thresholdOffset?: number;
  thresholdExceedCallback?: () => void;
  thresholdReturnCallback?: () => void;
} = {}) {
  const [triggered, setTriggered] = React.useState(false);

  React.useEffect(() => {
    const listener = () => {
      if (window.scrollY >= thresholdOffset && !triggered) {
        setTriggered(true);
        thresholdExceedCallback();
      }
      if (window.scrollY <= thresholdOffset && triggered) {
        setTriggered(false);
        thresholdReturnCallback();
      }
    };

    listener();

    window.addEventListener("scroll", listener);

    return () => window.removeEventListener("scroll", listener);
  }, [triggered, thresholdOffset]);

  return null;
}

export default useScroll;
