import kyInstance from '@/shared/api/kyInstance';
import { localStorageService } from '@/shared/lib/helpers';

import type { APIResponseType } from '../types';
import { authAxiosInstance } from './authAxiosInstance';
import { API_AUTH_ENDPOINTS, API_AUTH_ENDPOINTS_V2 } from './constants';
import type { FetchPostLogin, FetchGetMe, FetchPostLogout, UserType, FetchPostLoginWithMiniApp } from './types';

export const fetchGetMe: FetchGetMe = async () => {
  // TODO temporary solution
  kyInstance.nicegram.post<APIResponseType<UserType>>(API_AUTH_ENDPOINTS_V2.getMe()).catch((e) => {
    console.error('v2 fetchGetMe', e);
  });

  const {
    data: axiosData,
    status,
    statusText,
  } = await authAxiosInstance.get<{
    user: UserType;
  }>(API_AUTH_ENDPOINTS.getMe(), {
    withCredentials: true,
  });

  return { data: axiosData.user, status, message: statusText };
};

export const fetchPostLogin: FetchPostLogin = async (body) => {
  try {
    // TODO temporary solution
    const response = await kyInstance.nicegram.post<APIResponseType<{ token: string }>>(API_AUTH_ENDPOINTS_V2.login(), {
      body: JSON.stringify(body),
    });

    localStorageService.userAccessToken.set(response.data.data?.token || '');
  } catch (e) {
    console.error('v2 fetchPostLogin', e);
  }

  const { status, statusText } = await authAxiosInstance.post(API_AUTH_ENDPOINTS.login(), body);

  return { data: [], status, message: statusText };
};

export const fetchPostLoginWithMiniApp: FetchPostLoginWithMiniApp = async (tgInitData) => {
  const initData = new URLSearchParams(tgInitData);
  const body = Array.from(initData.entries()).reduce((acc, [key, value]) => {
    if (key === 'user') {
      acc[key] = JSON.parse(value);
    } else {
      acc[key] = value;
    }

    return acc;
  }, {} as any);

  try {
    // TODO temporary solution
    const response = await kyInstance.nicegram.post<APIResponseType<{ token: string }>>(
      API_AUTH_ENDPOINTS_V2.loginWithMiniApp(),
      {
        body: JSON.stringify(body),
      },
    );

    localStorageService.userAccessToken.set(response.data.data?.token || '');
  } catch (e) {
    console.error('v2 fetchPostLogin', e);
  }

  const { status, statusText } = await authAxiosInstance.post(API_AUTH_ENDPOINTS.loginWithMiniApp(), body);

  return { data: [], status, message: statusText };
};

export const fetchPostLogout: FetchPostLogout = async () => {
  // TODO temporary solution
  kyInstance.nicegram.post<APIResponseType<[]>>(API_AUTH_ENDPOINTS_V2.logout()).catch((e) => {
    console.error('v2 fetchPostLogout', e);
  });

  const { status, statusText } = await authAxiosInstance.post(API_AUTH_ENDPOINTS.logout(), undefined, {
    withCredentials: true,
  });

  return { data: [], status, message: statusText };
};
