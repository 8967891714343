import type { APIResponseType } from '@/shared/api/types';
import kyInstance from '@/shared/api/kyInstance';
import { settingsAxiosInstance } from './settingsAxiosInstance';
import { API_SETTINGS_ENDPOINTS, API_SETTINGS_ENDPOINTS_V2 } from './constants';
import { FetchGetUserSettings, FetchPostUserSettings, UserSettings, FetchPostUserSettingsV2 } from './types';

export const fetchGetUserSettings: FetchGetUserSettings = async (id: number) => {
  // TODO temporary solution
  kyInstance.nicegram.get<APIResponseType<UserSettings>>(API_SETTINGS_ENDPOINTS_V2.settings()).catch((e) => {
    console.error('v2 fetchGetUserSettings', e);
  });

  const {
    status,
    statusText,
    data: axiosData,
  } = await settingsAxiosInstance.get<{
    settings: UserSettings;
  }>(API_SETTINGS_ENDPOINTS.getSettingsByUserId(id), {
    withCredentials: true,
  });

  const settings = axiosData.settings;

  // TODO temporary solution
  if (settings) {
    try {
      await fetchPostUserSettingsV2({
        showExplicit: settings.show_explicit,
        allowSafeModeEdit: settings.allow_safe_mode_edit,
      });
    } catch (e) {
      console.error('v2 fetchGetUserSettings', e);
    }
  }

  return { data: settings, status, message: statusText };
};

// TODO temporary solution
export const fetchPostUserSettingsV2: FetchPostUserSettingsV2 = (settings) =>
  kyInstance.nicegram.post(API_SETTINGS_ENDPOINTS_V2.settings(), {
    body: JSON.stringify(settings),
  });

export const fetchPostUserSettings: FetchPostUserSettings = async (settings: UserSettings) => {
  // TODO temporary solution
  fetchPostUserSettingsV2({
    showExplicit: settings.show_explicit,
    allowSafeModeEdit: settings.allow_safe_mode_edit,
  }).catch((e) => {
    console.error('v2 fetchPostUserSettings', e);
  });

  const { status, statusText } = await settingsAxiosInstance.post(API_SETTINGS_ENDPOINTS.settings(), settings, {
    withCredentials: true,
  });

  return { data: [], status, message: statusText };
};
