import type { Input, KyResponse, Options } from 'ky';
import type { CustomKYResponseType } from '@/shared/api/types';
import { localStorageService, isClient } from '@/shared/lib/helpers';

import { createKyInstance } from './createKyInstance';

const formatKyResponsePromise = async <Data extends unknown>(
  responsePromise: Promise<KyResponse>,
): Promise<CustomKYResponseType<Data>> => {
  const res = await responsePromise;

  return {
    headers: res.headers,
    data: await res.json<Data>(),
  };
};

type APIServicesType = 'nicegram';

const initKyInstance = (service: APIServicesType, additional: { headers?: Options['headers'] } = {}) => {
  const instance = createKyInstance(service);
  const { headers } = additional;

  const getCommonOptions = (options?: Options): Options =>
    ({
      ...options,
      credentials: 'include',
      headers: {
        ...headers,
        ...options?.headers,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-unblock-feature-token': isClient() ? localStorageService.userAccessToken.get() ?? '' : '',
      },
      next: {
        revalidate: 0,
        ...options?.next,
      },
    } as Options);

  return {
    ...instance,

    get: <Data extends unknown>(url: Input, options?: Options) =>
      formatKyResponsePromise<Data>(
        instance.get(url, {
          ...getCommonOptions(options),
          searchParams:
            options?.searchParams !== undefined ? JSON.parse(JSON.stringify(options.searchParams)) : undefined,
        }),
      ),

    post: <Data extends unknown>(url: Input, options?: Options) =>
      formatKyResponsePromise<Data>(instance.post(url, getCommonOptions(options))),

    put: <Data extends unknown>(url: Input, options?: Options) =>
      formatKyResponsePromise<Data>(instance.put(url, getCommonOptions(options))),

    patch: <Data extends unknown>(url: Input, options?: Options) =>
      formatKyResponsePromise<Data>(instance.patch(url, getCommonOptions(options))),

    delete: <Data extends unknown>(url: Input, options?: Options) =>
      formatKyResponsePromise<Data>(instance.delete(url, getCommonOptions(options))),
  };
};

export { initKyInstance };
