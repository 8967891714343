"use client";

import React from "react";
import { useUserInfoContext } from "@/processes/auth";
import { usePathname, useRouter } from "@/shared/lib/hooks";

function AuthRouter({ children }: React.PropsWithChildren) {
  const router = useRouter();
  const pathname = usePathname();
  const { user, isFetching } = useUserInfoContext();

  React.useEffect(() => {
    if (isFetching) return;

    if (!user && pathname === "/settings") {
      router.push("/");
    }
  }, [isFetching, user]);

  return children;
}

export default AuthRouter;
