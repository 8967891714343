import styled from "styled-components";

export const LogoutButton = styled.button`
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  border: none;
  padding: 8px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export const Wrapper = styled.div<{ $pinned: boolean }>`
  padding: 0 50px;
  position: ${(props) => (props.$pinned ? "fixed" : "relative")};
  background: ${(props) => (props.$pinned ? "#1c1c1c" : "transparent")};
  z-index: 1000;
  width: 100%;
  transition: 0.2s ease;
  top: 0;
  margin-top: -1px;

  nav {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;

    > div {
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }

  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;
