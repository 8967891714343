import authService from './authService';
import settingsService from './settingsService';
import type * as AuthServiceTypes from './authService';
import type * as SettingsServiceTypes from './settingsService';

const api = {
  authService,
  settingsService,
};

export default api;

export type { AuthServiceTypes, SettingsServiceTypes };
