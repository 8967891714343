import styled, { css } from "styled-components";

export const Dropdown = styled.div`
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  border-radius: 20px;
  padding: 10px 15px;
  background-color: rgba(0, 0, 0, 0.25);
  max-height: calc(100vh - 140px);
  overflow-y: auto;
`;

export const DropdownHeader = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;

export const LanguageOptionFlag = styled.div<{ $src?: string }>`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: url(${(props) => props.$src}) center center;
  background-size: cover;
  flex-shrink: 0;
`;
export const LanguageOptionLabel = styled.div`
  color: var(--primary-text);
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  text-align: left;
  font-weight: 600;
`;

export const LanguageOption = styled.div`
  display: flex;
  gap: 0 5px;
  align-items: center;
  cursor: pointer;
`;

export const LanguageOptionsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 15px;
`;

export const ActiveLanguageOptionFlag = styled.div<{ $src?: string }>`
  width: 16px;
  height: 16px;
  border-radius: 100vmax;
  background: url(${(props) => props.$src}) center center;
  background-size: cover;

  @media (max-width: 768px) {
    width: 24px;
    height: 24px;
  }
`;
export const ActiveLanguageOptionLabel = styled.div`
  color: var(--primary-text);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.1px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    display: none;
  }
`;
export const ActiveLanguageOption = styled.div<{ $hide?: boolean }>`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;

  ${({ $hide }) =>
    $hide &&
    css`
      visibility: hidden;
    `}
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
