import type { LanguageOption } from "./types";

const options: LanguageOption[] = [
  {
    iso: "en",
    label: "English",
    region: "United States",
    icon: "/staticfiles/locales/us.svg",
  },
  {
    iso: "ru",
    label: "Русский",
    region: "Россия",
    icon: "/staticfiles/locales/ru.svg",
  },
  {
    iso: "es",
    label: "Español",
    region: "España",
    icon: "/staticfiles/locales/es.svg",
  },
  {
    iso: "zh-CN",
    label: "汉语 (简体)",
    region: "中国",
    icon: "/staticfiles/locales/cn.svg",
  },
  {
    iso: "zh-TW",
    label: "漢語 (繁體)",
    region: "中國",
    icon: "/staticfiles/locales/cn.svg",
  },
  {
    iso: "vi",
    label: "Tiếng Việt",
    region: "Việt Nam",
    icon: "/staticfiles/locales/vn.svg",
  },
  {
    iso: "tr",
    label: "Türkçe",
    region: "Türkiye",
    icon: "/staticfiles/locales/tr.svg",
  },
  {
    iso: "pt",
    label: "Português",
    region: "Portugal",
    icon: "/staticfiles/locales/pt.svg",
  },
  {
    iso: "ko",
    label: "한국어",
    region: "대한민국",
    icon: "/staticfiles/locales/kr.svg",
  },
  {
    iso: "it",
    label: "Italiano",
    region: "Italia",
    icon: "/staticfiles/locales/it.svg",
  },
  {
    iso: "fr",
    label: "Français",
    region: "France",
    icon: "/staticfiles/locales/fr.svg",
  },
  {
    iso: "de",
    label: "Deutsch",
    region: "Deutschland",
    icon: "/staticfiles/locales/de.svg",
  },
  {
    iso: "ar",
    label: "العربية",
    region: "الإمارات العربية المتحدة",
    icon: "/staticfiles/locales/uae-min.png",
  },
];
export default options;
