import * as authApi from './auth.crud';
import type * as AuthTypes from './types';

const authService = {
  auth: authApi,
};

export default authService;

export type { AuthTypes };
